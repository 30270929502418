/* eslint-disable */
import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-3">
                <p>
                    A <strong>iOpos Acadèmia</strong>, t’ajudem a superar amb èxit les{" "}
                    <strong>proves psicoprofessionals de Mossos d'Esquadra</strong> amb una
                    preparació <strong>completa i específica</strong>.
                </p>

                <p>
                    <strong>📌 Què has de saber?</strong>
                </p>
                <p>
                    En les darreres convocatòries, el test psicoprofessional ha estat principalment
                    de <strong>personalitat</strong>, però anteriorment també incloïa preguntes{" "}
                    <strong>competencials</strong> i <strong>clíniques</strong>.
                </p>

                <p>
                    <strong>
                        🔍 Per això, hem creat una formació adaptada als tres tipus de tests
                        oficials, que inclou:
                    </strong>
                </p>
                <ul>
                    <li>
                        ✅ Correcció detallada i personalitzada per entendre els teus resultats.
                    </li>
                    <li>
                        ✅ 3 gràfiques exclusives sobre el teu perfil clínic, competencial i de
                        personalitat.
                    </li>
                    <li>✅ 1 sessió individual via ZOOM per optimitzar les teves respostes.</li>
                </ul>

                <p>
                    <strong>🔹 Per què triar iOpos?</strong>
                </p>
                <p>
                    Som <strong>especialistes en proves psicoprofessionals</strong> i t’oferim una{" "}
                    <strong>preparació personalitzada</strong> perquè el teu perfil s’ajusti als
                    requisits oficials i augmenti les teves possibilitats d'èxit.
                </p>

                <p>
                    <strong>📢 Vols preparar-te amb garanties? Segueix aquests passos:</strong>
                </p>
                <ol>
                    <li>
                        Reserva la teva plaça{" "}
                        <strong>
                            <a href="#">aquí</a>
                        </strong>
                        .
                    </li>
                    <li>
                        Envia’ns un correu a 👉{" "}
                        <a href="mailto:testpsicos@iopos.academy">testpsicos@iopos.academy</a>.
                    </li>
                    <li>Completa els tests i rep la teva gràfica personalitzada.</li>
                    <li>
                        Accedeix a la sessió de correcció i{" "}
                        <strong>maximitza els teus resultats</strong>.
                    </li>
                </ol>

                <p>
                    <strong>💰 Preu: 149,99 €</strong>
                </p>

                <p>
                    <strong>🚀 Prepara’t amb els millors i aconsegueix la teva plaça!</strong>
                </p>

                {/*<div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/YUe0b5qv5F0"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>*/}
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Descobreix el teu perfil policial i prepara't amb garanties per a les oposicions de Mossos d'Esquadra, Guàrdia Urbana o Policies Locals amb el nostre servei personalitzat d'anàlisi psicològica. Reserva ara i obté la teva gràfica personalitzada amb orientació experta a través de Zoom!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};
export default Product;
